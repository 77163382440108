import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from '../../Shared/Models/constant';


@Injectable({
  providedIn: 'root'
})
export class BranchService {


  constructor(private httpClient:HttpClient) { 
    
  }
  Constants = new Constant();
  private clientbranch = this.Constants.localhost_url + 'api/branchall/';
  private branchDeleteUrl = this.Constants.localhost_url + 'api/branch/';
  private branchnew = this.Constants.localhost_url + 'api/branch';
  private branchdetail = this.Constants.localhost_url + 'api/branch/';
  private branchEdit = this.Constants.localhost_url + 'api/branch/';
  private delete_letter_head = this.Constants.localhost_url + 'api/delete-letter-head?';

  branchlist(id:string,page:any='all',SearchText=''): Observable<any> {
    
    return this.httpClient.get(`${this.clientbranch}`+id+'?page='+page+"&q="+SearchText);
  }
  branchDelete(id){
    
    return this.httpClient.delete(`${this.branchDeleteUrl}`+id);
  }
  Branchnew(clientbranchdata:Object): Observable<any> {
    
    return this.httpClient.post(`${this.branchnew}`,clientbranchdata);
  }
  BranchDetail(id:string): Observable<any> {
   
    return this.httpClient.get(`${this.branchdetail}`+id); 
  }
  BranchEdit(ClientsbranchEdit:Object,id:string): Observable<any> {
    
    return this.httpClient.post(`${this.branchEdit}`+id+"?_method=PUT",ClientsbranchEdit);
  }
  DeleteHead(letter_head_delete_type,id,delete_type):Observable<any>{
    

    return this.httpClient.get(`${this.delete_letter_head}letter_head_delete_type=${letter_head_delete_type}&id=${id}&delete_type=${delete_type}`); 

  }
}
