
<div class="app-body" style="background-image: url(assets/img/brand/Admin\ Authentication.png); margin-top: 0px;">
  <div class="preloader" id="loader" style="background-image: url('assets/img/preloader.gif'); position: fixed; display: none; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 999999; background-color: rgb(255, 255, 255); background-position: center center; background-repeat: no-repeat; overflow: hidden;">
</div>

  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card-group" style="margin-right: 16px;margin-left: 16px;">
            <div class="card p-4">
              <div class="card-body">


                <form *ngIf="show_login"  [formGroup]="Loginforms">
                  <div style="text-align: center;">
                    <img src="assets/moval_images/Group (1).png" class="btn-bg"
                      style="height: 56px; border-radius: 6px;margin-bottom: 44px; background-color: #fe4a49;">
                  </div>
                  <div class="form-group">
                    <label for="Enter Your Email"><p style="font-family: sans-serif;margin-bottom: .1rem;">Enter Your Email</p> </label>
                    <div class="input-group mb-3" style="margin-bottom: 0rem !important;" >
                      <input type="email" class="form-control" formControlName="email" maxlength="40" placeholder="abc123@mail.com" required [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    </div>
                    <div *ngIf="LoginEmail.invalid && LoginEmail.touched || submitted">
                      <small *ngIf="LoginEmail.errors?.required" style="font-family: sans-serif;margin-bottom: .1rem;font-size: inherit;" class="text-danger">Email is required
                      </small>
                      <small style="font-family: sans-serif;margin-bottom: .1rem;font-size: inherit;" *ngIf="LoginEmail.errors?.pattern" class="text-danger">Please provide a valid Email</small>
                    </div>
                  </div>
                  <div class="form-group"> 
                    <label for="Enter Your Password">
                      <p style="font-family: sans-serif;margin-bottom: .1rem;">Enter Your Password</p>
                    </label>
                    <div class="input-group mb-4" style="margin-bottom: 0rem !important;">
                      <input type="password" class="form-control" formControlName="Password" placeholder="***********" required [ngClass]="{ 'is-invalid': submitted && f.Password.errors }">
                    </div>
                    <div class="input-group input-group-merge">
                      <small style="font-family: sans-serif;margin-bottom: .1rem;font-size: inherit;" class="form-text text-danger" *ngIf="!Loginforms.get('Password').dirty && (Loginforms.get('Password').touched || submitted)">
                        Password is Required
                      </small>
                    </div>
                  </div> 
                  <div class="form-group"> 
                    <label for="Platform">
                      <p style="font-family: sans-serif;margin-bottom: .1rem;">Platform</p>
                    </label>
                      <select aria-label="Default select example" formControlName="platform" cSelect class="custom-select" required [ngClass]="{ 'is-invalid': submitted && f.platform.errors }">
                        <option value="">Select Platform</option>
                        <option value="2">Motor Survey</option>
                        <option value="1">Motor Valuation</option>  
                      </select>     
                      <div class="input-group input-group-merge">
                        <small style="font-family: sans-serif;margin-bottom: .1rem;font-size: inherit;" class="form-text text-danger" *ngIf="!Loginforms.get('platform').dirty && (Loginforms.get('platform').touched || submitted)">
                          Platform is Required
                        </small>
                      </div>      
                  </div> 


                  <div style="display:none" class="form-group">
                    <label for="Enter Your Email"><p style="font-family: sans-serif;margin-bottom: .1rem;">Login Type</p> </label>
                    <fieldset id="radioUserType">
                        <input checked="checked" formControlName="radioUserType"  id="sa" type="radio" value="super_admin" name="radioUserType"/>
                        <label for="sa">Super Admin</label>

                        <input formControlName="radioUserType"  id="ot" type="radio" value="other" name="radioUserType"/>
                        <label for="ot">Admin / Employee</label>
                    </fieldset>
                  </div>

                  <div class="col-12 text-right">
                    <button (click)="showForgot()" type="button" class="btn btn-link px-0" style="margin-top: -38px;">Forgot password?</button>
                  </div>
                  <div class="row">
                    <div class="col-12" style="margin-top: 21px;margin-bottom: 14px;">
                      <button type="submit" (click)="onSignup(Loginforms.value)" style="width: 100%;" class="btn px-4 btn-bg">Sign In</button>
                    </div>
                  </div>
                </form>




                <form *ngIf="show_forgot"  [formGroup]="Forgotforms">
                  <div style="text-align: center;">
                    <img src="assets/moval_images/Group (1).png" class="btn-bg"
                      style="height: 56px; border-radius: 6px;margin-bottom: 44px; background-color: #fe4a49;">
                  </div>
                  <div class="form-group">
                    <label for="Enter Your Email"><p style="font-family: sans-serif;margin-bottom: .1rem;">Enter Your Email</p> </label>
                    <div class="input-group mb-3" style="margin-bottom: 0rem !important;" >
                      <input type="text" class="form-control" formControlName="user_email" maxlength="40" placeholder="Enter Your Email" required [ngClass]="{ 'is-invalid': submitted && fo.user_email.errors }">
                    </div>
                    
                  </div>
                  <div class="form-group">
                    <select aria-label="Default select example" formControlName="platform" cSelect class="custom-select" required [ngClass]="{ 'is-invalid': submitted && fo.platform.errors }">
                      <option value="">Select Platform</option>
                      <option value="2">Motor Survey</option>
                      <option value="1">Motor Valuation</option>  
                    </select> 
                  </div>
                 

                  <div class="col-12 text-right">
                    <button (click)="showLogin()" type="button" class="btn btn-link px-0" style="margin-top: -38px;">Login</button>
                  </div>
                  <div class="row">
                    <div class="col-12" style="margin-top: 21px;margin-bottom: 14px;">
                      <button type="submit" (click)="onSignForgot(Forgotforms.value)" style="width: 100%;" class="btn px-4 btn-bg">Submit</button>
                    </div>
                  </div>
                </form>




                 <form *ngIf="show_otp"  [formGroup]="Otpforms">
                  <div style="text-align: center;">
                    <img src="assets/moval_images/Group (1).png" class="btn-bg"
                    style="height: 56px; border-radius: 6px;margin-bottom: 44px; background-color: #fe4a49;">
                  </div>
                  <div class="form-group">
                    <label for="Enter Your OTP"><p style="font-family: sans-serif;margin-bottom: .1rem;">Enter Your  OTP</p> </label>
                    <div class="input-group mb-3" style="margin-bottom: 0rem !important;" >
                      <input type="text" class="form-control" formControlName="user_otp" maxlength="40" placeholder="Enter Your OTP" required [ngClass]="{ 'is-invalid': submitted && foo.user_otp.errors }">
                    
                    </div>
                    
                  </div>
                 

                  <div class="col-12 text-right">
                    <button (click)="showForgot()" type="button" class="btn btn-link px-0" style="margin-top: -38px;">Reset</button>
                  </div>
                  <div class="row">
                    <div class="col-12" style="margin-top: 21px;margin-bottom: 14px;">
                      <button type="submit" (click)="onVerifyOTP(Otpforms.value)" style="width: 100%;" class="btn px-4 btn-bg">Submit</button>
                      <small><b>Note:</b> Check your Inbox as well as Spam for OTP</small>
                    </div>
                  </div>
                </form>



              <form *ngIf="show_change_password" [formGroup]="changepasswordform">
                <div style="text-align: center;">
                    <img src="assets/moval_images/Group (1).png" class="btn"
                      style="height: 56px; border-radius: 6px;margin-bottom: 44px; background-color: #fe4a49;">
                 </div>
                
                <div class="form-group">
                  <div class="input-group" style="padding-top: 20px;">
                    <input type="password" class="form-control" formControlName="password"
                      placeholder="Enter New Password" required>
                  </div>
                  <div class="input-group input-group-merge">
                    <small class="form-text text-danger"
                      *ngIf="!changepasswordform.get('password').valid && (changepasswordform.get('password').dirty || changepasswordform.get('password').touched || submitted)">
                      New Password is Required
                    </small>
                    <small *ngIf="passwordError != ''" class="text-danger">{{passwordError}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group" style="padding-top: 20px;">
                    <input type="password" class="form-control" formControlName="password_confirmation"
                      placeholder="Confirmation Password" required>
                  </div>
                  <div class="input-group input-group-merge">
                    <div *ngIf="this.changepasswordform.controls.password_confirmation.touched && this.changepasswordform.controls.password_confirmation.invalid || submitted">
                      <small *ngIf="this.changepasswordform.controls.password_confirmation.required" class="text-danger">Confirm Password is
                        required.</small>
                      <small *ngIf="this.changepasswordform.controls.password_confirmation.confirmedValidator" class="text-danger">Password and
                        Confirm Password must match.</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" style="margin-top: 28px;margin-bottom: 14px;">
                    <button type="button" (click)="ChangePaassword(changepasswordform.value)"
                      style="width: 100%;" class="btn px-4 btn-bg">Submit</button>
                  </div>
                </div>
              </form>









              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>